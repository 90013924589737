.clickable {
  cursor: pointer;
}

body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  /*position: absolute;*/
  min-height: 100%;
  min-height: 100vh;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }


.dropdown-menu .active {
  color: #007bff;
}

/* Animate */

[animate-on-change].changed {
  background-color: #ffdd35;
  transition: none;
  -webkit-transition: none;
}

[animate-on-change] {
  transition: all 1s;
  -webkit-transition: all 1s;
}

.datetime-picker-dropdown .uib-timepicker{
  margin: 0 auto;
}


.dropdown-menu a {
  display:block;
  width:100%;
  padding: 5px;
  text-decoration: none;
  color: black;
}

.dropdown-menu .active a{
  color: white;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu .dropdown-header {
  padding: 0 10px;
}

.text-header {
  color: #999;
}

.text-link {
  color: #AAA;
}

.text-link:hover {
  color: white;
}

.alarms {
  color: darkred;
  background-color: rgba(255, 0, 0, 0.19);
  border: 1px solid red;
}

:root {
  --input-padding-x: .75rem;
  --input-padding-y: .75rem;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 30px auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
